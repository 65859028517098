import Route from 'mewe/routes/route';

export default class PublicPostRoute extends Route {

  model(params) {
    return {
      postId: params.post_id,
    };
  }

}
