import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { showNewToMeWePopup } from 'mewe/utils/dialogs-common';
import { isMobile } from 'mewe/shared/utils';
import { menuHeaderMewe, ENTITY_TYPE } from 'mewe/constants';

export default class MwInvite extends Component {
  menuHeaderMeweConst = menuHeaderMewe;

  @service account;
  @service dynamicDialogs;

  @tracked section = 'posts';
  @tracked viewTimeline = false;

  isMobile = isMobile();

  constructor() {
    super(...arguments);

    this.setPageMeta();
  }

  setPageMeta() {
    document.title = __('To follow this page join or log-in to MeWe today');
  }

  @computed('args.model.contact.{id,nsfw,_links.avatar.nsfw,following,public}')
  get isNsfwBlurred() {
    if (this.args.model.contact.following) return false;
    if (this.args.model.contact.id === this.account.activeUser.id) return false;

    return this.args.model.contact._links?.avatar?.nsfw;
  }

  get isOpenProfile() {
    return this.args.model.entityType === ENTITY_TYPE.PROFILE;
  }

  get isOpenPage() {
    return this.args.model.entityType === ENTITY_TYPE.PAGE;
  }

  @action
  displayNewToMeWePopup() {
    showNewToMeWePopup(this.dynamicDialogs);
  }

  @action
  selectAbout() {
    this.section = 'about';
  }

  @action
  selectPosts() {
    this.section = 'posts';
  }

  @action
  showTimeline() {
    this.viewTimeline = true;
  }
}
