/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { A } from '@ember/array';

import { search } from 'mewe/pods/components/others/mw-share-with/utils';
import Scrolling from 'mewe/utils/scrolling-utils';
import { Target, TargetContact } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import FollowingApi from 'mewe/api/following-api';
import { inject as service } from '@ember/service';
import { fetchUsersCounters } from 'mewe/fetchers/fetch-followers';
import { ds } from 'mewe/stores/ds';

export default class MwSharingPublicPost extends Component {
  @service account;

  scrolling = Scrolling();

  @tracked usersCounters = ds.usersCounters;
  @tracked followers = A();
  @tracked followersLoading = true;
  @tracked followersNextPage = null;
  @tracked favorites = A();
  @tracked favoritesLoading = true;
  @tracked favoritesNextPage = null;
  @tracked searchResults = null;
  @tracked friendsPicker = false;
  @tracked searchValue = '';

  constructor() {
    super(...arguments);

    if (this.isTargetContact) {
      fetchUsersCounters();
    }

    this.account.activeUser.deferred.promise.then(() => {
      this.setPostingType(this.account.activeUser.public ? TargetContact.PUBLIC : TargetContact.PRIVATE);
    });
  }

  @action
  onInsert(element) {
    this.element = element;
  }

  @action
  onDestroy() {
    this.scrolling.unbindScrollDown(this.element.querySelector('.friends_content'));
  }

  async loadFollowers(nextPage = null) {
    this.followersLoading = true;
    let data = await FollowingApi.favoriteCandidates({ limit: 15, nextPage });
    let list = data?.list ? A([...data.list]) : A();

    if (nextPage) {
      this.pushUnique(this.followers, list);
    } else {
      this.followers = list;
    }

    this.followersLoading = false;
    this.followersNextPage = data?._links?.nextPage?.href || null;

    if (this.followersNextPage) {
      this.scrolling.bindScrollDownElement(
        this.element.querySelector('.friends_content'),
        () => this.loadMoreFollowers(),
        300,
        true
      );
    }

    this.args.fetchingUsersUpdate();
  }

  async loadFavorites(nextPage = null) {
    this.favoritesLoading = true;
    let data = await FollowingApi.followersFavorite({ limit: 15, nextPage });
    let list = data?.list ? A([...data.list]) : A();
    let usersList = A([...list.map((item) => Object.assign(item, item.user))]);

    if (nextPage) {
      this.pushUnique(this.favorites, usersList);
    } else {
      this.favorites = usersList;
    }

    this.favoritesLoading = false;
    this.favoritesNextPage = data?._links?.nextPage?.href || null;
    this.args.fetchingUsersUpdate();
  }

  @action
  pushUnique(collection, newItems) {
    // accitional checking if item with same id already exists in collection,
    // because we might movd items between followers and favorites lists when adding/removing favorite.
    // usecase: itemA is removed from favorites (object moved to followers) and then it's loaded
    // on second page of followers list (because he'd not be loaded on first page)
    newItems.forEach((item) => {
      if (!collection.findBy('id', item.id)) {
        collection.pushObject(item);
      }
    });
  }

  @action
  loadMoreFavorites() {
    this.loadFavorites(this.favoritesNextPage);
  }

  @action
  loadMoreFollowers() {
    this.loadFollowers(this.followersNextPage);
  }

  get isPublic() {
    if (this.account.activeUser.public && !this.args.postingType) {
      return TargetContact.PUBLIC;
    }

    return this.args.postingType === TargetContact.PUBLIC;
  }

  get isPrivate() {
    if (!this.account.activeUser.public && !this.args.postingType) {
      return TargetContact.PRIVATE;
    }

    return this.args.postingType == TargetContact.PRIVATE;
  }

  get scrollStyle() {
    const isRtl = document.dir == 'rtl',
      w = isRtl ? '50%' : '-50%';
    return htmlSafe(`transform: translateX(${this.friendsPicker ? w : 0})`);
  }

  get isFriends() {
    return this.args.postingType == TargetContact.FRIENDS;
  }

  get showNoFollowingPlaceholder() {
    return !this.followers.length && !this.followersLoading && !this.favorites.length && !this.favoritesLoading;
  }

  @action
  doSearch(searchValue) {
    const str = searchValue.trim();

    if (str.length) {
      this.searchResults = search(str, Target.CONTACTS_FRIENDS, this.favorites);
    } else {
      this.searchResults = null;
      this.closeSearch();
    }
  }

  @action
  toggle(user) {
    if (user.favorite) {
      dispatcher.dispatch('contact', 'removeUserFromFavorite', user,
        () => {
          this.removeFavoriteCb(user);
        }
      );
    } else {
      dispatcher.dispatch('contact', 'addUserToFavorite', user,
        () => {
          this.addFavoriteCb(user);

          if (!this.isFriends) {
            this.setPostingType(TargetContact.FRIENDS);
          }
        }
      );
    }
  }

  @action
  toggleFriendsPicker(e) {
    e?.stopPropagation();

    if (!this.friendsPicker) {
      this.loadFollowers();
      this.loadFavorites();
    } else {
      this.searchValue = ''; // clear search when leaving friends picker
    }

    if (this.isFriends && !this.usersCounters.favoriteFollowers) {
      this.setPostingType(TargetContact.PRIVATE);
    }

    this.friendsPicker = !this.friendsPicker;
  }

  @action
  toggleFriendsPickerIfNoFavorites() {
    if (!this.usersCounters.favoriteFollowers) {
      this.toggleFriendsPicker();
    } else {
      this.setPostingType(TargetContact.FRIENDS);
    }
  }

  @action
  setPostingType(type) {
    this.args.setPostingType(type);
  }

  @action
  addFavoriteCb(user) {
    // user param can be from search results or from followers/favorites list,
    // that's why we need to updated both - user from param and user from followers/favorites list
    set(user, 'favorite', true);
    // 'favorites' is auto updated by dispatcher, 'favoriteFollowers' is additional update from this particular usecase
    ds.usersCounters.incrementProperty('favoriteFollowers');

    const userObj = this.followers.findBy('id', user.id);
    // if user exists in followers list, we need to move it to favorites list
    // otherwise we add user object from param to favorites list
    if (userObj) {
      set(userObj, 'favorite', true);
      this.followers.removeObject(userObj);
      this.favorites.addObject(userObj);
    } else {
      this.favorites.addObject(user);
    }
  }

  @action
  removeFavoriteCb(user) {
    // user param can be from search results or from followers/favorites list,
    // that's why we need to updated both - user from param and user from followers/favorites list
    set(user, 'favorite', false);
    // 'favorites' is auto updated by dispatcher, 'favoriteFollowers' is additional update from this particular usecase
    ds.usersCounters.decrementProperty('favoriteFollowers');

    const userObj = this.favorites.findBy('id', user.id);
    // if user exists in favorites list, we need to move it to followers list
    // otherwise we add user object from param to followers list
    if (userObj) {
      set(userObj, 'favorite', false);
      this.favorites.removeObject(userObj);
      this.followers.addObject(userObj);
    } else {
      this.followers.addObject(user);
    }
  }

  @action
  closeSearch() {
    this.searchValue = '';
  }

  get isTargetContact() {
    return this.args.target == Target.CONTACTS;
  }

  @action
  togglePostComments() {
    this.args.togglePostComments?.();
  }
}
