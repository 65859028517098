/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { getQueryStringParams } from 'mewe/shared/utils';
import AccountApi from 'mewe/api/account-api';
import Session from 'mewe/shared/session';
import * as Sentry from '@sentry/ember';
import { isMobile } from 'mewe/shared/utils';

export default class MwDsnpLoginConfirmComponent extends Component {
  @service router;
  @service analytics;
  @service dynamicDialogs;
  @service authentication;

  @tracked promisesToWaitFor = [];
  @tracked requestFailed = false;

  constructor() {
    super(...arguments);

    const loginPromise = new Promise((resolve) => (this.resolvePromise = resolve));
    this.promisesToWaitFor.push(loginPromise);

    this.urlParams = getQueryStringParams();

    const platform = this.urlParams?.platform;

    if ((platform === 'ios' || platform === 'android') && isMobile()) {
      this.showAppDialog();

      // we want to trigger this only once, that's why it's done
      // here in constructor instead of showAppDialog function
      this.analytics.sendEvent('popupViewed', 'Confirmation Link Redirect');
    } else {
      // logged in user should go to app
      // but if it's mobile then suggesting redirection to app has priority still
      Session.isAuthenticated().then(({ isAuthenticated }) => {
        if (isAuthenticated) {
          window.location = '/';
        } else {
          this.doLogin();
        }
      });
    }
  }

  showAppDialog() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      hideCloseButton: true, // can be closed by "cancel" button only
      doNotEscapeClose: true,
      okButtonText: __('Open MeWe app'),
      cancelButtonText: __('Use browser'),
      message: __(
        `We recommend using the MeWe app on your mobile device. If you have it installed the button below will open it for you.`
      ),
      onConfirm: () => {
        window.location = `mewe://${window.location.host}${window.location.pathname}${window.location.search}`;
        // confirm button closes the popup. In case the app is not installed we show the popup again so that user can try browser option
        setTimeout(() => this.showAppDialog(), 2000);

        if (!this.redirectAppTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - app');
          this.redirectAppTracked = true; // we want to track this only once
        }
      },
      onClose: () => {
        if (!this.redirectBrowserTracked) {
          this.analytics.sendEvent('buttonClicked', 'Confirmation Link Redirect - browser');
          this.redirectAppTracked = true; // we want to track this only once
        }

        this.doLogin();
      },
    });
  }

  doLogin() {
    let { token, sessionId, email, trc, t } = this.urlParams;

    if (!email || !token || !sessionId) {
      const missingParams = `${!email ? 'email, ' : ''}${!token ? 'token, ' : ''}${!sessionId ? 'sessionId, ' : ''}`;
      Sentry.captureException(new Error(`Web3 login email confirmation - missing params: ${missingParams}`));
      return;
    }

    const params = {
      username: email,
      sessionId: sessionId,
      code: token,
      t: t || Date.now(),
    };
    const reqOptions = {
      traceId: trc,
      xDsnp: true,
    };

    AccountApi.login(params, reqOptions)
      .then((res) => {
        this.authentication.loginCallback(params, res, this.urlParams);
      })
      .catch((res) => {
        if (res.data?.errorCode === 128) {
          this.requestFailed = 'tokenConsumed';
        } else if (res.data?.errorCode === 124) {
          this.requestFailed = 'expired';
        } else {
          this.requestFailed = true;
        }

        Sentry.captureException(
          new Error(
            `Web3 login email confirmation - login request failed. Status: ${res.status}, ${
              res.data?.message || res.message
            }`
          )
        );

        // this will finish the loading animation and show error screen
        this.resolvePromise();
      });
  }

  @action
  retryRedirect() {
    // email passed to be pre-filled in the login form
    this.router.transitionTo('login', { queryParams: this.urlParams });
  }
}
