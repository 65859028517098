import keyMirror from 'keymirror';
import config from 'mewe/config';

export const commentsMaxResults = 10;
export const newCommentsVisible = 3;
export const postsPerPage = 10;
export const maxPostsForFTUE = 5; // ftue posts will be shown when posts.length below or equal to this
export const chatMsgsPerPage = 25;
export const maxChatSuggestions = 5;
export const maxWrappersResults = 15;
export const maxPostsInWrapper = 10;
export const pageNotificationsCenter = 30;
export const maxFilesToUpload = config.maxFileLimit;
export const minSmartSearchPhraseLength = 2;
export const maxResultsEventsList = 20;
export const maxResultsMembersList = 30;
export const mentionAutocompleteMaxResults = 10;
export const maxResultsTagsList = 30;
export const maxMentionsInText = 20;
export const chatMaxUsers = config.chatMaxUsers;
export const msgMaxLength = config.maxMessageLimit;
export const maxPollOptions = 20;
export const chatMsgsPerFirstLoad = 25;
export const chatWindowHeightToSubtratct = 260; // experimental value, depends on current layout sizes
export const maxCustomChatNameLength = 25;
export const maxGifHeight = 250;
export const photoMaxWidthHeight = 4000;
export const photoMaxWeight = 20000000; // 20MB
export const photoMaxWeightToResizeInBrowser = 30000000; // 30MB
export const photoMaxWeightToUploadToServer = 50000000; // 50MB
export const videoMaxWeightToUploadToServer = config.environment != 'prod' ? 64000000 : 500000000; // 500MB (64MB on master)
export const fileUploadLimit = 500000000; // 500MB
export const highlightTime = 4000;
export const tosAcceptanceCacheTime = 3600000; // backend tos-value may not be up-to-date due to caching, so don't show tos popup for 1h after acceptance
export const maxContacts = config.maxContacts || 8000;
export const maxFavorites = config.maxFavorites || 200;
export const maxContactsLabel = '8k+';
export const maxAttachmentsPerPost = 50;
export const maxEmailInvitationsAtOnce = 50;
export const maxHashtags = 30;
export const imgFormats = ['png', 'x-png', 'gif', 'jpg', 'jpeg'];
export const iosAppUrl = 'https://itunes.apple.com/us/app/mewe-network/id918464474';
export const androidAppUrl = 'https://play.google.com/store/apps/details?id=com.mewe';
export const giphyContentRating = 'pg-13';

export const videoMaxWeightToUploadToServerBig = 5000000000; // 5GB
export const usersAllowedToUploadBiggerVideo = [
  '6064cdbe60e3b24b2bfa49e3',
  '5889a5a2ba98d03336acd771',
  '5874951351ee970d69d0d0c1',
  '6482262c6cadd05dba09a623',
  '5df156f1cf7750663a63c1d8',
  '610052d7ab34314176a5c88e',
  '62d9c8279dd48b48c418eb8c',
  '5cfa2aa14eddd07d431314b8',
  '5bbc9ad6a5f4e532ebac0169',
];

// url params used for DSNP email confirmation links for tracking user journey during
// login/registration started on public pages. Also carried in the URL between public views/login/registratoin
// !keep in sync with register/login controller!
export const publicIdEmailParams = [
  'gpId', // group public id
  'epId', // event public id
  'ppId', // page public id
  'upId', // user public id
  'inId', // invitation id
];

// url params that can be passed to registration form to prefill it
export const signupPrefillParams = ['email', 'fname', 'lname', 'handle'];

export const Locales = {
  en: 'English',
  ja: '日本語',
  'zh-HK': '中文(香港)',
  'zh-CN': '中文(简体)',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  'pt-BR': 'Português',
  ar: 'العربية',
  da: 'Dansk',
  ko: '한국어',
  nl: 'Nederlands',
  pl: 'Polski',
  he: 'עברית‏',
  it: 'Italiano',
  fi: 'Suomi',
  sv: 'Svenska',
  nb: 'Norsk (bokmål)',
  el: 'Ελληνικά',
  cs: 'Čeština',
};

export const ChatEventTypes = {
  USERJOINED: 'ParticipantJoined',
  USERLEFT: 'ParticipantLeft',
  CHATRENAMED: 'ChatNameChanged',
};

export const NotificationTypes = keyMirror({
  discover_feature_info: null,
  discover_group_post_published_owner: null,
  discover_page_post_published_owner: null,
  discover_group_post_published_admin: null,
  discover_page_post_published_admin: null,
  group_invitation: null,
  group_application_invitation: null,
  group_ownership_transferred: null,
  event_invitation: null,
  event_deleted: null,
  event_reminder: null,
  event_updated: null,
  event_attendance: null,
  group_public_directory_removed: null,
  invitation_accepted: null,
  secret_chat_request: null,
  contact_birthday: null,
  group_application_approved: null,
  contactSuggestion: null,
  contact_auto_added: null,
  group_application_request: null,
  page_invitation: null,
  limited_comment: null,
  comment: null,
  limited_post_edit: null,
  limited_post: null,
  emojis: null,
  mention: null,
  post: null,
  poll_vote: null,
  poll_ended: null,
  newPageFollower: null,
  storage_exceeded: null,
  contacts_limit_warning: null,
  scheduled_posts_failed: null,
  web3_handle_ready: null,
});

export const TaskStates = keyMirror({
  UNSENT: null,
  LOADING: null,
  DONE: null,
  FAIL: null,
});

export const FeedTypes = {
  ALL: 'all',
  CONTACT: 'contact',
  MEMBER: 'member', // isn't it the same as CONTACT?
  CONTACTS: 'contacts', // IT IS MYWORLD
  GROUP: 'group',
  THREAD: 'thread',
  GROUP_PENDING: 'group-pending',
  GROUP_PREVIEW: 'group-preview',
  GROUPS: 'groups',
  PRIVACYMAIL: 'privacymail',
  GROUP_CONTACT: 'group-contact',
  EVENT: 'event',
  EVENT_PENDING: 'event-pending',
  EVENT_CONTACT: 'event-contact',
  PAGE: 'page',
  PAGES: 'pages',
  SCHEDULED_PAGE_POSTS: 'scheduled-page-posts',
  SCHEDULED_PROFILE_POSTS: 'scheduled-profile-posts',
  SCHEDULED_GROUP_POSTS: 'scheduled-group-posts',
  PAGE_ANALYTICS: 'page-analytics',
  WRAPPER: 'wrapper',
  DISCOVER: 'discover',
  FAVORITES: 'favorites',
  OPEN_PROFILE: 'open-profile',
  OPEN_PAGE: 'open-page',
};

export const Theme = {
  CONTACTS: 'contacts',
  MYCLOUD: 'mycloud',
  CHAT: 'chat',
  EVENT: 'event',
  EVENTS: 'events',
  GROUP: 'group',
  GROUPS: 'groups',
  PROFILE: 'profile',
  OPEN_PROFILE: 'open-profile',
  PRIVATEPOSTS: 'privacymail',
  PAGES: 'pages',
  PAGE: 'page',
  PAGE_LINK_ID: 'page-link-id',
};

export const Target = {
  CONTACTS: 'contacts',
  CONTACTS_FRIENDS: 'contacts_friends',
  EVENT: 'event',
  GROUP: 'group',
  PRIVATEPOST: 'privacymail',
  PAGE: 'page',
  MYCLOUD: 'mycloud',
};

export const TargetContact = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  FRIENDS: 'friends',
  EVERYONE: 'everyone',
};

export const WrapperScope = {
  ALL: 'all',
  GROUP: 'group',
  PAGE: 'page',
  EVENT: 'event',
};

export const stickInParentOffsetTop = 74;

export const bigWeight = 10000000;

const MB = 1000000,
  KB = 1000;
export const prettyWeight = (weight) =>
  weight > 1000000
    ? `${parseFloat(weight / MB, 2).toFixed(2)}MB`
    : weight < 1000
    ? `${parseFloat(weight, 2).toFixed(2)}B`
    : `${parseFloat(weight / KB, 2).toFixed(2)}KB`;
export const hasBigPhoto = (arr) => arr.find((el) => el.size > bigWeight);

export const Colors = {
  APP: '#6A7F00',
};

export const ChatFilter = {
  ALL: 'AllChat',
  USER: 'UserChat',
  GROUP: 'GroupChat',
  EVENT: 'EventChat',
};

export const TellerType = {
  PAGE: 'Page',
  USER: 'User',
};

export const translationFields = [
  {
    type: 'profile',
    idField: 'userId',
    translations: ['currentCity', 'job', 'company', 'highSchool', 'college', 'relationshipStatus', 'interests', 'text'],
  },
  {
    type: 'post',
    idField: 'postId',
  },
  {
    type: 'comment',
    idField: 'commentId',
  },
  {
    type: 'group',
    idField: 'groupId',
    questionsParentContainer: 'groupQuestions',
    answersParentContainer: 'answers',
    translations: ['questionDisplay', 'answerDisplay'],
  },
];

export const menuHeaderMewe = [
  {
    testid: 'header-nav-my-world',
    name: 'Home',
    svgName: 'svg_home',
  },
  {
    testid: 'header-nav-chat',
    name: 'Chats',
    svgName: 'icn_chat',
  },
  {
    testid: 'header-nav-groups',
    name: 'Groups',
    svgName: 'icn_groups',
  },
  {
    testid: 'header-nav-pages',
    name: 'Pages',
    svgName: 'svg_page',
  },
  {
    testid: 'header-nav-events',
    name: 'Events',
    svgName: 'svg_event',
  },
];

export const ENTITY_TYPE = {
  PROFILE: 'profile',
  PAGE: 'page',
};
