import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import Component from '@ember/component';

import { getStickersDeferred } from 'mewe/utils/emoji-utils';
import { reposition } from 'mewe/utils/popup-utils';

import PurchasesStore from 'mewe/stores/purchases-store';
import Scrolling from 'mewe/utils/scrolling-utils';
import dispatcher from 'mewe/dispatcher';

import layout from './template.hbs';
import './styles.scss';
import StickerApi from 'mewe/api/sticker-api';
import { isEmpty } from '@ember/utils';

const packUrls = {
  'spring-fling': 'assets/stickers/logo/spring-fling-logo.png',
  'winter-joy': 'assets/stickers/logo/winter-joy-logo.png',
  'donuts-jelly': 'assets/stickers/logo/donuts-jelly.png',
  'avocado-adventures': 'assets/stickers/logo/avocado-adventures.png',
  'eggplant-life': 'assets/stickers/logo/eggplant-life.png',
  'happy-poo': 'assets/stickers/logo/happy-poo.png',
  'mermaid-life': 'assets/stickers/logo/mermaid-life.png',
  'peach-life': 'assets/stickers/logo/peach-life.png',
  'smiley-guy': 'assets/stickers/logo/smiley-guy.png',
  'unicorn-life': 'assets/stickers/logo/unicorn-life.png',
  'woman-power': 'assets/stickers/logo/woman-power.png',
  'sweet-sassy': 'assets/stickers/logo/sweet-sassy.png',
  'halloween-party': 'assets/stickers/logo/halloween-party-logo.png',
  'christmas-merry': 'assets/stickers/christmas-merry.png',
  'naughty-dozen': 'assets/stickers/naughty-dozen.png',
};

const MwStickerPickerComponent = Component.extend({
  layout: layout,

  classNames: ['sticker-picker p-absolute border-box radius-16 o-hidden'],
  classNameBindings: ['open::invisible'],

  stickers: null,

  selectedId: 'holiday23_free',

  aiStickers: [],

  packs: [
    'holiday23_free',
    'summer-fun_free',
    'spring-fling',
    'winter-joy',
    'donuts-jelly',
    'avocado-adventures',
    'eggplant-life',
    'happy-poo',
    'mermaid-life',
    'peach-life',
    'smiley-guy',
    'unicorn-life',
    'woman-power',
    'sweet-sassy',
    'halloween-party',
    'christmas-merry',
    'naughty-dozen',
  ],

  freePacks: ['stickers-holiday23_free', 'stickers-summer-fun_free'],

  scrollIndex: 0,

  pickers: service(),

  account: service(),

  scrolling: Scrolling(),

  init: function () {
    this._super(...arguments);

    this.closeOnOutsideBind = this.closeOnOutside.bind(this);

    getStickersDeferred().promise.then((data) => {
      this.set('stickers', data);
    });

    this.set('storeState', PurchasesStore.getState());

    this.append();

    this.pickers.register({
      name: 'stickers',
      close: () => {
        this.send('close');
      },
    });
  },

  purchased: computed('storeState.purchasedItems.length', function () {
    return this.storeState.purchasedItems.filter((el) => ~el.indexOf('stickers-')).concat(this.freePacks);
  }),

  scrollStyle: computed('scrollIndex', function () {
    return htmlSafe(`transform: translateX(${this.scrollIndex * 48}px)`);
  }),

  selectedStickers: computed('selectedId', 'stickers', function () {
    return this.get(`stickers.${'stickers-' + this.selectedId}.stickers`) || [];
  }),

  isOwned: computed('selectedId', 'purchased', function () {
    return this.purchased.indexOf('stickers-' + this.selectedId) > -1;
  }),

  selectedPackName: computed('selectedId', function () {
    return this.selectedId
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }),

  selectedPackLogoUrl: computed('selectedId', function () {
    return `/${packUrls[this.selectedId]}`;
  }),

  sprite: computed('stickers', 'selectedId', function () {
    if (!this.stickers) return null;

    return {
      url: this.get(`stickers.${'stickers-' + this.selectedId}.pngSpriteSheet.default`),
      size: this.get(`stickers.${'stickers-' + this.selectedId}.pngSpriteSize`),
    };
  }),

  arrowLeftVisible: computed('packs', 'scrollIndex', function () {
    if (this.packs.length <= 8) return false;

    const isRtl = document.dir;
    return isRtl ? this.packs.length - this.scrollIndex > 8 : this.scrollIndex < 0;
  }),

  arrowRightVisible: computed('packs', 'scrollIndex', function () {
    if (this.packs.length <= 8) return false;

    const isRtl = document.dir;
    return isRtl ? this.scrollIndex > 0 : this.scrollIndex + this.packs.length > 8;
  }),

  isGeneratedStickers: computed('selectedId', function () {
    return this.selectedId === 'my_sticker' || this.selectedId === 'gen_sticker'
  }),

  isMyStickers: computed('selectedId', function () {
    return this.selectedId === 'my_sticker'
  }),

  generatedStickers: computed('aiStickers', function() {
    if (isEmpty(this.aiStickers)) {
      return;
    }
    return this.aiStickers.map(sticker => {
      sticker.url = `${location.origin}/api/v2/stickers/generated/${sticker.id}/file`;
      return sticker;
    })
  }),

  isOpen() {
    return this.open;
  },

  getDialogWrapper() {
    return document.querySelector('.dialog_wrapper');
  },

  closeOnOutside(e) {
    if (!this.open) return;

    if (this.element !== e.target && this.element.contains(e.target)) return;

    if (!this.button || e.target != this.button) {
      this.send('close');
    }
  },

  actions: {
    async loadMyStickers() {
      if (!this.account.flagAIStickersEnabled) {
        return;
      }
      
      const response = await StickerApi.loadGeneratedSticker();
      await response.stickers.forEach(async(sticker) => {
        return await StickerApi.downloadGeneratedSticker(sticker.id); 
      });
      this.set('aiStickers', response.stickers);
    },

    selectSticker(sticker) {
      if (sticker.status) {
        this.callback({id: sticker.id, status: 'done'});
      } else {
        if (!this.isOwned) return;
        this.callback({ package: 'stickers-' + this.selectedId, id: sticker.name });
      }  


      this.set('open', false);
    },

    selectPack(id) {
      this.element.querySelector('main').scrollTop = 0;
      this.set('selectedId', id);
    },

    open({ isFixedPosition }) {
      this.pickers.open({ name: 'stickers' });

      this.set('open', true);

      if (this.getDialogWrapper()) {
        this.scrolling.bindScrollTreshold(
          this.getDialogWrapper(),
          () => {
            this.send('close');
          },
          10,
          'sticker-picker-close'
        );
      }

      if (isFixedPosition) {
        this.element.style.position = 'fixed';
        this.element.style.bottom = '30px';
        this.element.style.left = `${this.spaceLeft}px`;
        this.element.style.top = 'auto';
      } else {
        this.element.style.position = 'absolute';
        this.element.style.bottom = 'auto';
        this.element.style.left = `${this.spaceLeft}px`;
        this.element.style.top = `${this.spaceTop}px`;
      }

      document.addEventListener('mousedown', this.closeOnOutsideBind);
    },

    close() {
      this.set('open', false);

      document.removeEventListener('mousedown', this.closeOnOutsideBind);

      this.scrolling.unbindScrollDown(this.getDialogWrapper());
    },

    scrollLeft() {
      this.decrementProperty('scrollIndex');
    },

    scrollRight() {
      this.incrementProperty('scrollIndex');
    },

    reposition(element) {
      const { width, height } = this.element.getBoundingClientRect();
      const placement = reposition(element, { width, height });

      this.set('spaceTop', placement.top);
      this.set('spaceLeft', placement.left);
    },

    setCallback(callback) {
      this.set('callback', callback);
    },

    buyPack() {
      this.send('close');

      dispatcher.dispatch('purchase', 'checkout', 'stickers-' + this.selectedId);
    },
  },
});

let instance = null;

export default function (owner) {
  if (instance) {
    return instance;
  } else {
    if (!owner) return null;

    owner.register('component:-stickers-picker', MwStickerPickerComponent);

    let ComponentFactory = owner.factoryFor('component:-stickers-picker');

    instance = ComponentFactory.create();

    return instance;
  }
}
