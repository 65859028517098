import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { bind } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { fetchAdministrated } from 'mewe/fetchers/fetch-pages';
import { ds } from 'mewe/stores/ds';
import { postCustomInteraction } from 'mewe/utils/post-utils';

export default class ButtonTargets extends Component {
  @service dynamicDialogs;

  element;
  handleMouseEnterBind;

  @tracked showTargetsDropdown = false;
  @tracked allowPage;
  @tracked parent;

  @action
  onDidInsert(element) {
    this.element = element;
    this.handleMouseEnterBind = bind(this, this.handleMouseEnter);
    this.element.addEventListener('mouseenter', this.handleMouseEnterBind);
  }

  @action
  onWillDestroy() {
    this.element.removeEventListener('mouseenter', this.handleMouseEnterBind);
  }

  get allowTooltip() {
    return this.args.tooltip && !this.showTargetsDropdown;
  }

  @action
  onClick() {
    this.parent = this.args.text
      ? this.element.querySelector('.dropdown-positioner')
      : this.element.querySelector('.dropdown-icon');

    fetchAdministrated({ limit: 100 }) // currently administrated pages aren't paginated, we can only load 100 and have hope (MW)
      .then(() => {
        this.allowPage = !!ds.administrated.items.length;
        this.showTargetsDropdown = true;
      });

    if (this.args.clickAction) {
      this.args.clickAction();
    }
  }

  @action
  closeTargetsDropdown() {
    this.showTargetsDropdown = false;
  }

  handleMouseEnter() {
    if (this.args.mouseEnterAction) {
      this.args.mouseEnterAction();
    }
  }
}
