import { assert } from '@ember/debug';
import ApiUtil from './api';
import { FeedTypes } from 'mewe/constants';

class Api extends ApiUtil {
  fetchAllFeed(params) {
    return this.getNextPageOr('/home/allfeed', params);
  }

  fetchContactsFeed(params, filter = null) {
    if (filter == FeedTypes.FAVORITES) {
      return this.getNextPageOr('/home/favorites/postsfeed', params);
    }
    return this.getNextPageOr('/home/postsfeed', params);
  }

  fetchGroupFeed(groupId, params) {
    assert('feed-api: You need to pass groupId.', groupId);

    return this.getNextPageOr(`/group/${groupId}/postsfeed`, params, 'apiRoot3');
  }

  fetchThreadFeed(params) {
    return this.getNextPageOr('/home/threads', params);
  }

  checkGroupPendingFeed(groupId) {
    return this.get(`/group/${groupId}/pending/postsfeed/check`, {}, 'apiRoot3');
  }

  fetchFeedGroupPending(groupId, params) {
    assert('feed-api: You need to pass groupId.', groupId);

    return this.getNextPageOr(`/group/${groupId}/pending/postsfeed`, params, 'apiRoot3');
  }

  fetchFeedGroupPreview(groupId) {
    return this.get(`/group/${groupId}/preview/postsfeed`, {}, 'apiRoot3');
  }

  fetchAllGroupsFeed(params) {
    return this.getNextPageOr('/groups/postsfeed', params, 'apiRoot3');
  }

  fetchPrivatePosts(params) {
    return this.getNextPageOr(`/privateposts/${params.threadId}/postsfeed`, params);
  }

  fetchMembersFeed(userId, groupId, params) {
    return this.getNextPageOr(`/group/${groupId}/member/${userId}/postsfeed`, params, 'apiRoot3');
  }

  fetchEventFeed(eventId, params) {
    return this.getNextPageOr(`/event/${eventId}/postsfeed`, params);
  }

  checkEventPendingFeed(eventId) {
    return this.get(`/event/${eventId}/pending/postsfeed/check`);
  }

  fetchEventFeedPending(eventId, params) {
    return this.getNextPageOr(`/event/${eventId}/pending/postsfeed`, params);
  }

  fetchEventMemberFeed(eventId, userId, params) {
    return this.getNextPageOr(`/event/${eventId}/participant/${userId}/postsfeed`, params);
  }

  getUserFeedScheduled(params) {
    return this.getNextPageOr(`/home/allscheduledfeed`, params);
  }

  checkGroupScheduledFeed(groupId) {
    return this.get(`/group/${groupId}/scheduled/postsfeed/info`, {}, 'apiRoot3');
  }

  getGroupFeedScheduled(groupId, params) {
    return this.getNextPageOr(`/group/${groupId}/scheduled/postsfeed`, params, 'apiRoot3');
  }

  getCampaignPost() {
    return this.get(`/campaign`);
  }

  dismissCampaignPost(campaignId, feedbackId) {
    if (campaignId) {
      return this.del(`/campaign/dismiss/${campaignId}`);
    } else if (feedbackId) {
      return this.del(`/campaign/feedback/dismiss/${feedbackId}`);
    }
  }

  voteCampaignPost(campaignId, feedbackId, params) {
    if (campaignId) {
      return this.post(`/campaign/vote/${campaignId}`, {
          data: JSON.stringify(params),
      });
    } else if (feedbackId) {
      return this.post(`/campaign/feedback/vote/${feedbackId}`, {
        data: JSON.stringify(params),
      });
    }
  }
}

export default new Api();
