import Component from '@glimmer/component';
import { action } from '@ember/object';

import MiscUtils from 'mewe/utils/miscellaneous-utils';
import FunctionalUtils from 'mewe/shared/functional-utils';
import EnvironmentUtils from 'mewe/utils/environment-utils';

export default class MwCopyLink extends Component {
  get post() {
    return this.args.post;
  }

  @action
  click() {
    const groupId = this.post.group.id;
    let link = '';

    if (this.post.pageId) {
      link = `/${this.post.page.publicLinkId}/show/${this.post.id}`;
    } else if (groupId && groupId !== 'contacts' && !this.post.event2) {
      link = `/group/${groupId}/show/${this.post.id}`;
    } else if (this.post.event2 && !this.post.eventInfo) {
      //SG-14139
      link = `/event/${this.post.event2.id}/show/${this.post.id}`;
    } else {
      link = `/post/show/${this.post.id}`;
    }

    MiscUtils.copyToClipboard(EnvironmentUtils.getHost() + link);
    FunctionalUtils.info(__('Copied to your clipboard'));
  }
}
