import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class MwPhotoPickerDropdown extends Component {
  @service dynamicDialogs;

  openPhotoPickerDialog(instantUpload) {
    this.dynamicDialogs.openDialog('choose-photo', {
      theme: this.args.theme,
      url: this.args.url,
      task: this.args.task,
      selectPhoto: this.args.selectPhoto,
      titles: this.args.titles,
      instantUpload: instantUpload,
    });
  }

  @action
  addFile() {
    this.openPhotoPickerDialog(true);
  }

  @action
  openCloudPicker() {
    this.openPhotoPickerDialog(false);
  }

  @action
  openOptionsDropdown() {
    if (!this.args.preventDefault && this.args.setDropdownMenuOpened) {
      this.args.setDropdownMenuOpened(true);
    }
  }

  @action
  closeDropdownMenu() {
    this.args.setDropdownMenuOpened(false);
  }
}
